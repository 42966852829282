define("discourse/plugins/discourse-chat-integration/admin/controllers/modals/admin-plugins-chat-integration-test", ["exports", "@ember/controller", "@ember/object/computed", "I18n", "discourse/mixins/modal-functionality", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/object", "@glimmer/tracking"], function (_exports, _controller, _computed, _I18n, _modalFunctionality, _ajax, _ajaxError, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AdminPluginsChatIntegrationTest = _exports.default = (_dec = (0, _computed.not)("model.topic_id"), (_class = class AdminPluginsChatIntegrationTest extends _controller.default.extend(_modalFunctionality.default) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "loading", _descriptor, this);
      _initializerDefineProperty(this, "sendDisabled", _descriptor2, this);
    }
    handleKeyUp(e) {
      if (e.code === "Enter" && !this.sendDisabled) {
        this.send();
      }
    }
    send() {
      if (this.sendDisabled) {
        return;
      }
      this.loading = true;
      (0, _ajax.ajax)("/admin/plugins/chat-integration/test", {
        data: {
          channel_id: this.model.channel.id,
          topic_id: this.model.topic_id
        },
        type: "POST"
      }).then(() => {
        this.loading = false;
        this.flash(_I18n.default.t("chat_integration.test_modal.success"), "success");
      }).catch(_ajaxError.popupAjaxError);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sendDisabled", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleKeyUp", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleKeyUp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "send", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "send"), _class.prototype)), _class));
});